import React from "react";
import ComingSoonImg from "../assets/images/comingSoon.svg";

const ComingSoon = () => {
  return (
    <div>
      <div
        className="h-dvh bg-cover bg-center"
        style={{ backgroundImage: `url(${ComingSoonImg})` }}
      ></div>
    </div>
  );
};

export default ComingSoon;
