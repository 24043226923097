import React, { useEffect, useState } from "react";

import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-right.svg";

const Join = () => {
  const [joinDetails, setjoinDetails] = useState([
    {
      course_duration: "",
      description: "",
      course_mode: "",
      heading: "",
      title: "",
      image: {
        url: "",
      },
    },
  ]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/api/cbr-courses?populate=*", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          setjoinDetails(data?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container mx-auto py-12">
      <div className="flex flex-col justify-between ">
        <div className="flex justify-between">
          <p className="text-gray-600 text-base">Join CBR</p>
          <button className="px-6 py-4 bg-mustardYellow text-darkGrey rounded-full font-medium ">
            View Contacts & Regions
          </button>
        </div>
        <h1 className="text-2xl font-normal text-gray-900 mb-4 ">
          Join thousands of committed Christians in consistently <br /> reading
          through the Bible
        </h1>
      </div>

      <div className="flex justify-between flex-wrap">
        {joinDetails.map((detail) => (
          <div className="w-[327px] md:w-[30%]">
            <div className="max-w-sm my-2 rounded-lg overflow-hidden shadow-lg h-[480px] w-full bg-white">
              <div className="relative h-60">
                <img
                  src={detail?.image?.url}
                  alt={detail?.title}
                  className="w-full h-full object-cover"
                />

                <div className="absolute w-full bottom-0 px-3 z-10 py-2 backdrop-blur-md">
                  <div className="flex justify-between items-center">
                    {/* Left side text */}
                    <div className="p-1 rounded-lg  ">
                      <p className="text-sm font-bold text-white">
                        {detail?.title}
                      </p>
                      <p className="text-sm font-normal text-white">
                        {detail?.course_duration}
                      </p>
                    </div>
                    {/* Right side text */}
                    <div className="text-white px-3 py-2">
                      <span className="text-sm font-medium">
                        {detail?.course_mode}
                      </span>
                    </div>
                  </div>
                </div>
              </div>

              {/* Content Section */}
              <div className="p-6 bg-white">
                <h2 className="text-xl font-medium mb-4">{detail?.heading}</h2>
                <p className="text-gray-600 text-sm mb-6">
                  {detail?.description}
                </p>

                {/* Contact Link */}
                <div className="flex items-center text-textYellow cursor-pointer">
                  <p className="font-medium mr-2">Contact us</p>
                  <ArrowUp />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Join;
