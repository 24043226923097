import React, { useState } from "react";

import { ReactComponent as GrowthIcon } from "../../../assets/icons/growth.svg";
import { ReactComponent as CharacterIcon } from "../../../assets/icons/character.svg";
import { ReactComponent as FellowshipIcon } from "../../../assets/icons/fellowship.svg";
import { ReactComponent as ServiceIcon } from "../../../assets/icons/service.svg";
import { ReactComponent as VictoryIcon } from "../../../assets/icons/victory.svg";
import { ReactComponent as WeightIcon } from "../../../assets/icons/weigh.svg";

import { ReactComponent as WhiteGrowthIcon } from "../../../assets/icons/white_growth.svg";
import { ReactComponent as WhiteWeightIcon } from "../../../assets/icons/white_weight.svg";
import { ReactComponent as WhiteFellowshipIcon } from "../../../assets/icons/white_fellowship.svg";
import { ReactComponent as WhiteVictoryIcon } from "../../../assets/icons/white_victory.svg";
import { ReactComponent as WhiteCharacterIcon } from "../../../assets/icons/white_character.svg";
import { ReactComponent as WhiteServiceIcon } from "../../../assets/icons/white_service.svg";

import { ReactComponent as BluePlus } from "../../../assets/icons/bluePlus.svg";
import { ReactComponent as PurplePlus } from "../../../assets/icons/purplePlus.svg";
import { ReactComponent as YellowPlus } from "../../../assets/icons/yellowPlus.svg";
import { ReactComponent as PinkPlus } from "../../../assets/icons/pinkPlus.svg";
import { ReactComponent as GreenPlus } from "../../../assets/icons/greenPlus.svg";

import { ReactComponent as BlueGreenPlus } from "../../../assets/icons/bluGreenPlus.svg";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";

interface Scripture {
  id: number;
  topIcon: JSX.Element;
  topic: string;
  content: string;
  bottomIcon: JSX.Element;
  backgroundColor?: string;
  verse?: string;
  flipIcon?: JSX.Element;
}

const scriptures: Scripture[] = [
  {
    id: 1,
    topIcon: <GrowthIcon />,
    topic: "Only the Word causes Spiritual Growth",
    content:
      "Like new born babies, crave pure spiritual milk, so that by it you may grow up in your salvation now that you have tasted that the Lord is good.",
    bottomIcon: <BluePlus />,
    backgroundColor: "#5764EB",
    verse: "1 Peter 2:2-3",
    flipIcon: <WhiteGrowthIcon />,
  },
  {
    id: 2,
    topIcon: <ServiceIcon />,
    topic: "Only the Word equips for effective service",
    content:
      "All Scripture is God-breathed and is useful for teaching, rebuking, correcting, and training in righteousness, so that the servant of God may be thoroughly equipped for every good work.",
    bottomIcon: <PinkPlus />,
    backgroundColor: "#FF5C7B",
    verse: "2 Timothy 3:16-17",
    flipIcon: <WhiteServiceIcon />,
  },
  {
    id: 3,
    topIcon: <VictoryIcon />,
    topic: "Applying Scripture gives us victory over sin and Satan",
    content:
      "I write to you, fathers, because you have known him who is from the beginning. I write to you, young men, because you are strong, and the Word of God lives in you, and you have overcome the evil one.",
    bottomIcon: <BlueGreenPlus />,
    backgroundColor: "#30B0C7",
    verse: "1 John 2:14",
    flipIcon: <WhiteVictoryIcon />,
  },
  {
    id: 4,
    topIcon: <WeightIcon />,
    topic: "The Discernment to know deceivers",
    content:
      "The coming of the lawless one will be in accordance with the work of Satan displayed in all kinds of counterfeit miracles, signs and wonders...they perish because they refused to love the truth.",
    bottomIcon: <YellowPlus />,

    backgroundColor: "#E59D22",
    verse: "2 Thessalonians 2:9-10",
    flipIcon: <WhiteWeightIcon />,
  },
  {
    id: 5,
    topIcon: <CharacterIcon />,
    topic: "We develop character through perseverance",
    content:
      "No, I beat my body and make it my slave so that after I have preached to others, I myself will not be disqualified for the prize.",
    bottomIcon: <GreenPlus />,
    backgroundColor: "#02C7BE",
    verse: "1 Corithians 9:27",
    flipIcon: <WhiteCharacterIcon />,
  },
  {
    id: 6,
    topIcon: <FellowshipIcon />,
    topic: "We fellowship with God through meditation",
    content:
      "Do not let this book of the Law depart from your mouth; meditate on it day and night, so that you may be careful to do everything written in it. Then you will be prosperous and successful..",
    bottomIcon: <PurplePlus />,
    backgroundColor: "#B051DE",
    verse: " Joshua 1:8",
    flipIcon: <WhiteFellowshipIcon />,
  },
];

const BenefitsOfCBR: React.FC = () => {
  const [flippedCards, setFlippedCards] = useState<Record<number, boolean>>({});

  const toggleCard = (id: number): void => {
    setFlippedCards((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  return (
    <div className="container mx-auto my-20  ">
      <div className="text-center mb-10">
        <p className="text-foundationBlue font-medium mb-1">
          Broad Benefits of CBR
        </p>
        <h1 className="text-gray-900 font-medium text-2xl">
          What you gain with Consistent Bible Reading
        </h1>
      </div>

      <div className="flex flex-wrap gap-6 justify-between">
        {scriptures.map((scripture) => {
          const isFlipped = flippedCards[scripture.id] || false;
          return (
            <div className="w-[327px] md:w-[30%]">
              <div
                className={`border shadow rounded-3xl w-full h-[279px] p-6 flex flex-col items-center transition-transform ${
                  isFlipped
                    ? "transform rotate-y-180 shadow-lg shadow-white"
                    : "shadow"
                }`}
                key={scripture.id}
                style={{
                  backgroundColor: isFlipped
                    ? scripture.backgroundColor
                    : "white",
                }}
              >
                {isFlipped ? (
                  <div className="flex flex-col items-center justify-center h-full ">
                    <div className=" mb-6 font-medium text-white">
                      {scripture.flipIcon}
                    </div>
                    <p className="font-normal text-white text-2xl ">
                      {scripture.verse}
                    </p>
                    <div
                      className="cursor-pointer right-6 absolute bottom-6"
                      onClick={() => toggleCard(scripture.id)}
                    >
                      <CloseIcon />
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center h-full">
                    <div className="flex gap-4 w-full font-medium">
                      {scripture.topIcon}
                      <p className="font-medium mt-1 text-lg">
                        {scripture.topic}
                      </p>
                    </div>
                    <p className="my-2 flex-grow font-thin text-gray-600 text-sm">
                      {scripture.content}
                    </p>
                    <div
                      className="cursor-pointer flex w-full justify-end"
                      onClick={() => toggleCard(scripture.id)}
                    >
                      {scripture.bottomIcon}
                    </div>
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BenefitsOfCBR;
