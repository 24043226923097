import React from "react";
import { ReactComponent as AfterHero } from "../../../assets/images/AfterHero.svg";
import { ReactComponent as GroupIcon } from "../../../assets/icons/group.svg";
import { ReactComponent as CalendarIcon } from "../../../assets/icons/calendar.svg";
import { ReactComponent as FreeIcon } from "../../../assets/icons/free.svg";

import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";
const features = [
  {
    statement: "Conducted in groups of enthusiastic members.",
    icon: <GroupIcon />,
  },
  {
    statement: "Participants meet once a month for seven months.",
    icon: <CalendarIcon />,
  },
  {
    statement: "Monthly meeting takes 3 hours.",
    icon: <ClockIcon />,
  },
  {
    statement:
      "Course offered free of charge in partnership with sponsoring church.",
    icon: <FreeIcon />,
  },
];

const MoreAboutCBR = () => {
  return (
    <div className="w-full bg-[#FDF8E7]">
      <div className="container mx-auto py-16">
        <div className="flex flex-col lg:flex-row items-center justify-between w-full">
          <div className="lg:w-1/2">
            <AfterHero className="w-[493px] h-[520px]" />
          </div>

          <div className="lg:w-1/2 space-y-6">
            <div>
              <p className="text-gray-600 font-medium mb-2">About CBR</p>
              <h2 className="text-3xl font-light text-gray-900 mb-4">
                We are establishing Christians as Consistent Bible Readers
              </h2>
              <p className="text-gray-600 font-light mb-8">
                CBR is a discipline-establishing course, designed to enable
                believers cultivate discipline in reading through the entire
                Bible daily and in a systematic way in seven months with a goal
                of finishing the Bible once every year after training.
              </p>
            </div>

            <div className="flex flex-wrap gap-6">
              {features.map((feature, index) => (
                <div key={index} className="flex flex-col  w-[calc(50%-12px)]">
                  <div className="mb-2 rounded-full">{feature.icon}</div>
                  <p className="text-gray-600 w-[220px] font-light text-sm">
                    {feature.statement}
                  </p>
                </div>
              ))}
            </div>

            <button className="mt-8 px-6 py-4 bg-mustardYellow text-darkGrey rounded-full font-medium ">
              Learn about CBR Program
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MoreAboutCBR;
