import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, MobileNav, IconButton } from "@material-tailwind/react";

const Nav = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navItems = [
    { to: "/", label: "Home" },
    { to: "/about", label: "About us" },
    { to: "/cbr-program", label: "CBR Program" },
    { to: "/resources", label: "Resources" },
    { to: "/regions-contacts", label: "Regions & Contacts" },
    { to: "/support-us", label: "Support us" },
  ];

  const navList = (
    <ul className="mt-2 text-black mb-4 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      {navItems.map((item) => (
        <li key={item.to}>
          <Link
            to={item.to}
            className={`px-2 lg:px-4 ${
              location.pathname === item.to ? "text-navYellow" : "text-black"
            }`}
          >
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="-my-6 -mx-8  w-[calc(100%+64px)] overflow-hidden backdrop-blur-sm">
      <Navbar className="max-w-full rounded-none">
        <div className="container mx-auto flex items-center justify-between text-blue-gray-900">
          <p className="w-1/5 text-2xl text-textBlue font-extrabold">CBR</p>
          <div className="flex items-center justify-between">
            <div className=" hidden lg:block">{navList}</div>

            <IconButton
              variant="text"
              className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenNav(!openNav)}
            >
              {openNav ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  className="h-6 w-6"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              )}
            </IconButton>
          </div>
        </div>
        <MobileNav open={openNav}>{navList}</MobileNav>
      </Navbar>
    </div>
  );
};

export default Nav;
