import React, { useEffect, useState } from "react";
import { ReactComponent as Footsteps } from "../../../assets/images/Footsteps.svg";

const TrackRecord = () => {
  const [stats, setStats] = useState([
    {
      number_to_date: "string",
      category: "string",
      description: "string",
      id: "number",
    },
  ]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/api/homepage-statistics?sort=number_to_date:desc", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(res=> res.json())
    .then(data => {
      if (data.data){
        setStats(data?.data)
      }
    })
    .catch(err => console.log(err))
  }, [])

  return (
    <div className="w-full bg-[#e0e7eb]">
    <div className="container mx-auto py-16">
        <div className="flex flex-col justify-between ">
              <div className="flex justify-between">
                <h3 className="text-gray-600 font-medium mb-2">Our Track Record</h3>
                <button className=" px-6 py-2 bg-mustardYellow text-gray-900 rounded-full font-medium ">
                Read Our Story
              </button>
              </div>
              <h2 className="text-4xl font-medium text-gray-900 mb-4">
              The Power of  the Journey
              </h2>
              <p className="font-light text-lg mb-8">
              The Ministry began as a calling in 1993 and has grown into a movement that equips believers <br/> with the 
habit of Consistent Bible Reading. 
              </p>
        </div>
        <div className="flex flex-col-reverse lg:flex-row items-center justify-between">
          <div className="lg:w-1/2 space-y-2">
            <div className="flex flex-wrap gap-6">
              {stats.map((stat, index) => (
                <div key={index} className="flex flex-col items-center w-[calc(50%-12px)]">
                  <h1 className="mb-4 font-semibold text-6xl text-textBlue">{stat.number_to_date}+</h1>
                  <p className="text-gray-600 font-semibold text-center text-xl mb-4">
                    {stat.category}
                  </p>
                  <p className="text-gray-600 font-light text-lg text-center">
                    {stat.description}
                  </p>
                </div>
              ))}
            </div>
          </div>
          <div className="lg:w-1/2 flex lg:justify-end">
            <Footsteps className="h-[520px]" />
          </div>
          
        </div>
    </div>
    </div>
  );
};

export default TrackRecord;
