import BenefitsOfCBR from "./components/BenefitsOfCBR";
import HS from "./components/HeroSection2";

import Join from "./components/Join";
import MoreAboutCBR from "./components/MoreAboutCBR";
import Testimonials from "./components/Testimonials";
import TrackRecord from "./components/TrackRecord";

const HomePage = () => {
  return (
    <div className="">
      <HS />
      <BenefitsOfCBR />
      <MoreAboutCBR />
      <Join />
      <TrackRecord />
      <Testimonials />
    </div>
  );
};

export default HomePage;
