import React from "react";
import { Outlet } from "react-router-dom";
import Nav from "../common/Nav";
import Footer from "../common/Footer";

function MainLayout() {
  return (
    <div>
      <div className="sticky max-h-[768px] top-0 z-10 h-max rounded-none px-4 py-2 lg:px-8 lg:py-4">
        <Nav />
      </div>

      <div className="">
        <Outlet></Outlet>
      </div>

      <Footer />
    </div>
  );
}

export default MainLayout;
