import { Route, Routes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";

import NotFound from "../common/NotFound";

import HomePage from "../pages/Home/HomePage";
import ComingSoon from "../common/ComingSoon";

function AppRoutes() {
  return (
    <Routes>
      <Route element={<MainLayout />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<ComingSoon />} />
        <Route path="/cbr-program" element={<ComingSoon />} />
        <Route path="/resources" element={<ComingSoon />} />
        <Route path="/regions-contacts" element={<ComingSoon />} />
        <Route path="/support-us" element={<ComingSoon />} />
      </Route>

      <Route path="*" element={<NotFound />}></Route>
    </Routes>
  );
}

export default AppRoutes;
