import React, { useEffect, useState } from "react";
import { Carousel, IconButton } from "@material-tailwind/react";

function Testimonials() {
  const [testimonials, setTestimonials] = useState([
    {
      summary: "string",
      full_testimonial: "string",
      name: "string",
      title: "string",
      id: "number",
      photo: {
        url: "",
      },
    },
  ]);

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URL + "/api/testimonials?populate=*", {
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.data) {
          setTestimonials(data?.data);
        }
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="container mx-auto py-16">
      <div className="flex flex-col items-center mb-10">
        <h1 className="text-gray-600">Testimonials</h1>
        <h1 className=" text-4xl font-medium">Transformation through CBR</h1>
      </div>
      <div className="  lg:h-[500px]">
        <Carousel
          className="rounded-xl mb-20"
          autoplay
          autoplayDelay={5000}
          transition={{ duration: 1.5, type: "tween" }}
          loop
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="amber"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-4 -translate-y-2/4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="-ml-1 h-7 w-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 19.5 8.25 12l7.5-7.5"
                ></path>
              </svg>
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="amber"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 !right-4 -translate-y-2/4"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="currentColor"
                aria-hidden="true"
                data-slot="icon"
                className="ml-1 h-7 w-7"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                ></path>
              </svg>
            </IconButton>
          )}
          navigation={({ setActiveIndex, activeIndex, length }) => (
            <div className="absolute lg:bottom-12 bottom-4 lg:left-20 left-12 z-50 flex -translate-x-2/4 gap-2">
              {new Array(length).fill("").map((_, i) => (
                <span
                  key={i}
                  className={`block h-2.5 cursor-pointer rounded-full transition-color content-[''] ${
                    activeIndex === i
                      ? "w-2.5 bg-mustardYellow"
                      : "w-2.5  border border-mustardYellow"
                  }`}
                  onClick={() => setActiveIndex(i)}
                />
              ))}
            </div>
          )}
        >
          {testimonials.map((testimonial) => {
            return (
              <div className="relative h-full w-full flex flex-col-reverse overflow-scroll lg:overflow-hidden lg:flex-row">
                <div className="lg:w-3/5 flex p-8 flex-col flex-wrap text-wrap lg:p-16 bg-darkGrey">
                  <p className="text-white lg:text-2xl mb-6 break-normal">
                    {testimonial.summary}
                  </p>
                  <p className="text-white text-sm mb-12 underline cursor-pointer ">
                    <a>Read full testimonial</a>
                  </p>
                  <p className="text-mustardYellow text-xl mb-2 break-normal">
                    - {testimonial.name}
                  </p>
                  <p className="text-white lg:text-lg break-normal">
                    {testimonial.title}
                  </p>
                </div>

                <div className="lg:w-2/5 ">
                  <img
                    src={testimonial?.photo?.url}
                    alt={testimonial.name}
                    className="h-full object-cover "
                  />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
