import React from "react";
import HeroImg from "../../../assets/images/Hero.svg";

const HS = () => {
  return (
    <div>
      <div
        className="h-dvh mt-3 bg-cover bg-center"
        style={{ backgroundImage: `url(${HeroImg})` }}
      >
        <div className="flex flex-col items-center  justify-center h-full bg-black/50">
          <h1 className="text-white p-10 border-4 items-center justify-center border-white text-8xl font-extrabold flex flex-col h-[396px]  w-[239px] gap-4">
            <span>C</span>
            <span>B</span>
            <span>R</span>
          </h1>

          <p className="text-white text-2xl font-semibold uppercase mt-6">
            Consistent Bible Reading
          </p>

          <button className="mt-16 px-6 py-4 bg-mustardYellow text-darkGrey text-xl rounded-full mb-10 font-bold ">
            How CBR Works
          </button>
        </div>
      </div>
    </div>
  );
};

export default HS;
