import React from "react";
import { ReactComponent as Whatsapp } from "../assets/icons/whatsapp.svg";
import { ReactComponent as Facebook } from "../assets/icons/facebook.svg";
import { ReactComponent as Email } from "../assets/icons/Email.svg";
import { ReactComponent as YouTube } from "../assets/icons/Youtube.svg";
import { ReactComponent as CallIcon } from "../assets/icons/Call.svg";
import { ReactComponent as Location } from "../assets/icons/Location.svg";

interface Props {
  title: string;
  item1: string;
  item2: string;
  item3?: string;
  item4?: string;
}

const footerDetails: Props[] = [
  {
    title: "Sitemap",
    item1: "Home ",
    item2: "About us",
    item3: "CBR Program",
    item4: "Support the Ministry",
  },
  {
    title: "Resources",
    item1: "Books",
    item2: "Training Videos",
  },
  {
    title: "Help Center",
    item1: "Asked Questions ",
    item2: "Contacts & Regions",
  },
];

const currentYear = new Date().getFullYear();

function Footer() {
  return (
    <div className="container mx-auto">
      <div className=" flex flex-col md:flex md:flex-row gap-20 lg:justify-between w-full mb-24 pt-16 ">
        <div className=" flex flex-col  gap-4">
          <h1 className="text-5xl text-navYellow font-bold">CBR</h1>
          <p className="leading-7 font-light text-gray-600 lg:w-[248px]">
            Empowering believers with the discipline of daily & systematic
            reading of God's word
          </p>

          <div className="flex gap-4">
            <Facebook />
            <YouTube />
          </div>
        </div>

        <div className="flex flex-row lg:w-1/2  justify-between ">
          {footerDetails.map((footer) => (
            <div>
              <h1 className="font-semibold text-lg mb-10 text-blackBlue">
                {footer?.title}
              </h1>

              <div className="flex text-activeDarkGrey font-normal flex-col gap-6">
                <p>{footer?.item1}</p>
                <p>{footer?.item2}</p>
                <p>{footer?.item3}</p>
                <p>{footer?.item4}</p>
              </div>
            </div>
          ))}
        </div>

        <div className=" flex flex-col  ">
          <h1 className=" font-semibold text-lg mb-10 text-blackBlue">
            Get in Touch
          </h1>
          <div className="flex text-activeDarkGrey font-normal flex-col gap-6">
            <p className="flex gap-2 items-center">
              {<Whatsapp onClick={() => console.log("Whatsapp")} />} |
              <CallIcon />
              +254 720 777789
            </p>
            <p className="flex gap-2 items-center">
              <Email />
              feastword@gmail.com
            </p>

            <p className="flex gap-2 ">
              <Location className="mt-1" />
              P.O. BOX 2131-010100 <br /> Nyeri, Kenya
            </p>
          </div>
        </div>
      </div>

      <div className=" flex flex-col lg:flex lg:flex-row font-normal text-sm justify-between py-9 border-t">
        <p className="lg:w-1/2  text-darkGrey">
          (c) {currentYear} CBR, A Program of The Word Feast | All Rights
          Reserved
        </p>

        <div className="flex gap-16 text-activeDarkGrey lg:justify-end lg:w-1/2">
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
